
import './assets/styles/default.scss';
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Form from '@/components/Form.vue';

export default defineComponent({
	components: {
		Header, Footer, Form,
	},
});
