<template>
	<div>
		<Header />
		<kinesis-container tag="main">
			<div class="pattern-blue">
				<div class="banner container-fluid g-0">
					<div class="row">
						<div class="col-12">
							<picture>
								<source :srcset="require('@/assets/images/header@2x.jpg') + ' 2x'">
								<img src="@/assets/images/header.jpg" class="banner__image w-100">
							</picture>
						</div>
					</div>
				</div>
				<div class="container-xl position-relative">
					<div class="row justify-content-center">
						<div class="col-12 col-md-6 pt-56 pb-md-56">
							<p class="text-center">
								<small class="text-sm">Major winner prize</small><br>
								<strong class="text-lg">Nicole Quiggin</strong><br>
								<br>

								<small class="text-sm">Runners-up</small><br>
								<strong>Carina Schumer</strong><br>
								<strong>Ellen Lin</strong><br>
								<strong>Mulyadi Tondi</strong><br>
								<strong>Kelly Spiers</strong><br>
								<strong>Rosemary St.John</strong><br>
								<strong>Marc Edwards</strong><br>
								<strong>Karen Supalak</strong><br>
								<strong>Rachael Brennan</strong><br>
								<strong>Michele Crawley</strong><br>
								<strong>Helena Tupila</strong>
							</p>
							<p class="text-center">
								Since ancient times, Japan has shown a great appreciation for elegant aesthetics and promoted the
								undeniable benefits of self-care regimes, including onsen experiences and forest bathing, that
								foster what truly matters — one’s inner beauty.<br><br>
								For the chance to nurture your beauty and win luxury skincare from SK-II and Shiseido, courtesy of
								Beauty Affairs, just find the specials symbols hidden across <a href="https://www.japan.travel/en/au/?utm_source=Info&utm_medium=Website&utm_campaign=HiddenBeauty" target="_blank">japan.travel/en/au</a>.
							</p>
						</div>
					</div>

					<picture>
						<source :srcset="require('@/assets/images/products-md@2x.png') + ' 2x'">
						<img src="@/assets/images/products-md.png" class="products-md d-block d-md-none w-100" aria-hidden="true">
					</picture>

					<div class="products-left d-none d-md-flex flex-column" aria-hidden="true">
						<kinesis-element :strength="-16" class="products-left__product-01">
							<picture>
								<source :srcset="require('@/assets/images/product_sk-ii_1@2x.png') + ' 2x'">
								<img src="@/assets/images/product_sk-ii_1.png">
							</picture>
						</kinesis-element>
						<kinesis-element :strength="10" class="products-left__product-02">
							<picture>
								<source :srcset="require('@/assets/images/product_shiseido_1@2x.png') + ' 2x'">
								<img src="@/assets/images/product_shiseido_1.png">
							</picture>
						</kinesis-element>
						<kinesis-element :strength="-7" class="products-left__product-03">
							<picture>
								<source :srcset="require('@/assets/images/product_sk-ii_5@2x.png') + ' 2x'">
								<img src="@/assets/images/product_sk-ii_5.png">
							</picture>
						</kinesis-element>
						<kinesis-element :strength="22" class="products-left__snow products-left__snow--01" />
						<kinesis-element :strength="-1" class="products-left__snow products-left__snow--02" />
						<kinesis-element :strength="-7" class="products-left__snow products-left__snow--03" />
						<kinesis-element :strength="16" class="products-left__snow products-left__snow--04" />
					</div>

					<div class="products-right d-none d-md-flex flex-column" aria-hidden="true">
						<kinesis-element :strength="10" class="products-right__product-01">
							<picture>
								<source :srcset="require('@/assets/images/product_sk-ii_3@2x.png') + ' 2x'">
								<img src="@/assets/images/product_sk-ii_3.png">
							</picture>
						</kinesis-element>
						<kinesis-element :strength="-15" class="products-right__product-02">
							<picture>
								<source :srcset="require('@/assets/images/product_shiseido_2@2x.png') + ' 2x'">
								<img src="@/assets/images/product_shiseido_2.png">
							</picture>
						</kinesis-element>
						<kinesis-element :strength="25" class="products-right__product-03">
							<picture>
								<source :srcset="require('@/assets/images/product_sk-ii_2@2x.png') + ' 2x'">
								<img src="@/assets/images/product_sk-ii_2.png">
							</picture>
						</kinesis-element>
						<kinesis-element :strength="20" class="products-right__snow products-right__snow--01" />
						<kinesis-element :strength="-9" class="products-right__snow products-right__snow--02" />
						<kinesis-element :strength="6" class="products-right__snow products-right__snow--03" />
						<kinesis-element :strength="-12" class="products-right__snow products-right__snow--04" />
						<kinesis-element :strength="9" class="products-right__snow products-right__snow--05" />
					</div>

					<div class="row justify-content-center">
						<div class="col-10 col-sm-6 col-md-3">
							<h3 class="mb-24">
								Major prize
							</h3>
							<picture>
								<source :srcset="require('@/assets/images/major-prize@2x.png') + ' 2x'">
								<img src="@/assets/images/major-prize.png" class="w-100">
							</picture>
						</div>
						<div class="col-10 col-sm-6 col-md-3">
							<h3 class="mb-24">
								Minor prizes
							</h3>
							<picture>
								<source :srcset="require('@/assets/images/minor-prize@2x.png') + ' 2x'">
								<img src="@/assets/images/minor-prize.png" class="w-100">
							</picture>
						</div>
					</div>

					<div class="howto row justify-content-center pb-88">
						<div class="col-12">
							<h2 class="my-40">
								How to enter
							</h2>
						</div>
						<div class="col-10 col-md-4 d-flex flex-column align-items-center">
							<h3>Step 1</h3>
							<img src="@/assets/images/step-1.svg" aria-hidden="true" class="my-24">
							<img src="@/assets/images/heading-search.svg" alt="Search" class="howto__heading mb-20">
							<p class="text-center">
								Look for the special symbols on <a href="https://www.japan.travel/en/au/?utm_source=Info&utm_medium=Website&utm_campaign=HiddenBeauty" target="_blank">japan.travel/en/au</a> by deciphering the clues.
							</p>
						</div>
						<div class="col-10 col-md-4 d-flex flex-column align-items-center mt-56 mt-md-0">
							<h3>Step 2</h3>
							<img src="@/assets/images/step-2.svg" aria-hidden="true" class="my-24">
							<img src="@/assets/images/heading-find.svg" alt="Find" class="howto__heading mb-20">
							<p class="text-center">
								When found, add the unique code to the entry form and receive one entry into the prize draw.
							</p>
						</div>
						<div class="col-10 col-md-4 d-flex flex-column align-items-center mt-56 mt-md-0">
							<h3>Step 3</h3>
							<img src="@/assets/images/step-3.svg" aria-hidden="true" class="my-24">
							<img src="@/assets/images/heading-repeat.svg" alt="Repeat" class="howto__heading mb-20">
							<p class="text-center">
								There are four symbols to discover, which means four chances to win! Start your search now.
							</p>
						</div>
					</div>
				</div>

				<div class="form container-fluid g-0 position-relative">
					<div class="row justify-content-center">
						<div class="form__outer col-10 col-sm-8 col-md-6 col-xl-4 order-1 order-md-0">
							<Form class="form__form" />
						</div>
						<div class="col-12 order-0 order-md-1 form__banner-outer">
							<picture>
								<source :srcset="require('@/assets/images/banner-bottom@2x.jpg') + ' 2x'">
								<img src="@/assets/images/banner-bottom.jpg" class="form__banner w-100">
							</picture>
						</div>
					</div>
				</div>
			</div>

			<div class="bottom pattern-light pt-88 mt-n88">
				<div class="container-xl bottom__white-container color-secondary">
					<div class="row">
						<div class="col-12">
							<h2 class="mt-88">
								Find the Symbols
							</h2>
							<p class="my-32 text-center text-lg">
								Use the clues to find where each special symbol is hidden on <a href="https://www.japan.travel/en/au/?utm_source=Info&utm_medium=Website&utm_campaign=HiddenBeauty" target="_blank">japan.travel/en/au</a>
							</p>
						</div>
					</div>
					<div class="row mt-32 justify-content-center">
						<div class="col-8 col-sm-6 col-md-3">
							<div class="row justify-content-center">
								<div class="col-10 col-md-12">
									<img src="@/assets/images/symbol-1.svg" aria-hidden="true" class="bottom__symbol w-100 mb-40">
								</div>
							</div>
							<h3>Clue</h3>
							<p class="text-md text-center">
								Travel beyond the main islands of Japan and explore <a href="https://www.japan.travel/en/au/experience/other-side-japan/?utm_source=Clue1&utm_medium=Website&utm_campaign=HiddenBeauty" target="_blank">the other side</a>
							</p>
						</div>
						<div class="col-8 col-sm-6 col-md-3">
							<div class="row justify-content-center">
								<div class="col-10 col-md-12">
									<img src="@/assets/images/symbol-2.svg" aria-hidden="true" class="bottom__symbol w-100 mb-40">
								</div>
							</div>
							<h3>Clue</h3>
							<p class="text-md text-center">
								Look no further for the perfect travel inspiration <a href="https://www.japan.travel/en/au/guide/?utm_source=Clue2&utm_medium=Website&utm_campaign=HiddenBeauty" target="_blank">guides to Japan</a>
							</p>
						</div>
						<div class="col-8 col-sm-6 col-md-3">
							<div class="row justify-content-center">
								<div class="col-10 col-md-12">
									<img src="@/assets/images/symbol-3.svg" aria-hidden="true" class="bottom__symbol w-100 mb-40">
								</div>
							</div>
							<h3>Clue</h3>
							<p class="text-md text-center">
								Discover the <a href="https://www.japan.travel/en/au/story/?utm_source=Clue3&utm_medium=Website&utm_campaign=HiddenBeauty" target="_blank">stories</a> of Japan’s time-forged iconic traditions
							</p>
						</div>
						<div class="col-8 col-sm-6 col-md-3">
							<div class="row justify-content-center">
								<div class="col-10 col-md-12">
									<img src="@/assets/images/symbol-4.svg" aria-hidden="true" class="bottom__symbol w-100 mb-40">
								</div>
							</div>
							<h3>Clue</h3>
							<p class="text-md text-center">
								Soak away your troubles with Japan’s beloved luxurious <a href="https://www.japan.travel/en/au/experience/luxury-side-japan?utm_source=Clue4&utm_medium=Website&utm_campaign=HiddenBeauty" target="_blank">onsen experiences</a>
							</p>
						</div>
					</div>
					<div class="row pt-88 pb-64">
						<div class="col-12">
							<p class="text-lg text-center">
								Explore the Japanese Collection at <a href="https://beautyaffairs.com.au" target="_blank">beautyaffairs.com.au</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</kinesis-container>
		<Footer />
	</div>
</template>

<script lang="ts">
import './assets/styles/default.scss';
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Form from '@/components/Form.vue';

export default defineComponent({
	components: {
		Header, Footer, Form,
	},
});
</script>

<style scoped lang="scss">
#app {
	overflow-x: hidden;
}

.pattern-blue {
	background: url(~@/assets/images/pattern-blue.png);
}

.pattern-light {
	background: white url(~@/assets/images/pattern-light.png);
}

.howto {
	&__heading {
		max-height: r(70);
	}
}

.banner {
	&__image {
		min-height: 60vh;
		object-fit: cover;
		mask-image: url(~@/assets/images/header-mask.svg);
		mask-size: cover;
		mask-position: bottom center;
	}
	@include media-breakpoint-up(md) {
		&__image {
			min-height: unset;
			mask-image: url(~@/assets/images/header-mask.svg);
			mask-size: contain;
		}
	}
}

.form {

	&__outer {
		//margin-top: r(-690);
		margin-top: -50%;
		z-index: 3;

		@include media-breakpoint-up(md) {
			position: relative;
			margin-top: 0;
		}
	}

	&__form {
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			left: 0;
		}
		z-index: 3;
		width: 100%;
	}

	&__banner {
		object-fit: cover;
		mask-image: url(~@/assets/images/banner-bottom-mask.svg);
		mask-size: cover;
		mask-position: center;

		&-outer {
			&:before {
				display: block;
				content: '';
				width: 100%;
				height: 90%;
				background: white url(~@/assets/images/pattern-light.png);
				position: absolute;
				bottom: 0;
				left: 0;

				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}

		@include media-breakpoint-up(md) {
			min-height: r(690);
		}
	}
}

.bottom {
	&__symbol {
		filter: drop-shadow(0 r(24) r(20) rgba(61, 61, 61, 0.1));
	}
}

.products-md {
	width: 110vw;
	min-width: 110vw;
	margin-left: -10vw;
}

.products-left {
	position: absolute;
	top: 0;
	left: 0;

	&__snow {
		position: absolute;
		background: white;
		opacity: .9;
		filter: blur(30px);
		border-radius: 50%;

		&--01 {
			right: 0;
			bottom: 100%;
			width: r(160);
			height: r(160);
		}

		&--02 {
			filter: blur(25px);
			right: -10%;
			bottom: 65%;
			width: r(88);
			height: r(88);
		}

		&--03 {
			filter: blur(25px);
			left: 0;
			bottom: 15%;
			width: r(88);
			height: r(88);
		}

		&--04 {
			opacity: 0.4;
			filter: blur(25px);
			right: -10%;
			bottom: -10%;
			width: r(112);
			height: r(112);
		}
	}

	picture {
		filter: drop-shadow(0px 26px 28px rgba(5, 13, 38, 0.4));
	}

	&__product-01 {
		margin-top: 30%;
	}
	&__product-02 {
		margin-top: -20%;
		margin-left: 30%;
	}
	&__product-03 {
		margin-top: -70%;
	}
}

.products-right {
	position: absolute;
	top: 0;
	right: 0;

	&__snow {
		position: absolute;
		background: white;
		opacity: .9;
		filter: blur(25px);
		border-radius: 50%;

		&--01 {
			filter: blur(30px);
			right: 0;
			bottom: 100%;
			width: r(160);
			height: r(160);
		}

		&--02 {
			opacity: .5;
			left: 0;
			bottom: 65%;
			width: r(100);
			height: r(100);
		}

		&--03 {
			opacity: .3;
			right: 0;
			bottom: 35%;
			width: r(108);
			height: r(108);
		}

		&--04 {
			opacity: 0.4;
			left: -20%;
			bottom: 8%;
			width: r(70);
			height: r(70);
		}

		&--05 {
			opacity: 0.5;
			right: 20%;
			bottom: -20%;
			width: r(124);
			height: r(124);
		}
	}

	picture {
		filter: drop-shadow(0px 26px 28px rgba(5, 13, 38, 0.4));
	}

	&__product-01 {
		margin-top: 30%;
	}
	&__product-02 {
		margin-top: -10%;
		margin-left: -20%;
	}
	&__product-03 {
		margin-top: -40%;
	}
}
</style>
