<template>
	<ValidationForm v-if="!status" v-slot="{ errors, meta }" class="form p-24 pt-32 color-secondary" @submit="onSubmit">
		<h2 class="h4 mb-0">
			<span v-if="!closed">Enter the prize draw</span>
			<span v-else>This competition is now closed</span>
		</h2>
		<p class="my-16 text-md text-center">
			<span v-if="!closed">Each unique symbol code gives you one entry into the draw. Find all four for more chances to win!</span>
			<span v-else>We'll contact winners and announce them on this website. Good luck!</span>
		</p>
		<div :class="[{'form--disabled': closed}]">
			<div :class="['row mb-16', {'has-error': !!errors.fname}]">
				<div class="col-12">
					<label for="fname" class="form__label d-block mb-8 text-sm fw-bold">First name</label>
					<Field
						id="fname"
						validate-on-input
						type="text"
						placeholder="First name"
						name="fname"
						rules="required"
						class="form__input w-100 px-12 py-8 text-md"
					/>
				</div>
			</div>

			<div :class="['row mb-16', {'has-error': !!errors.lname}]">
				<div class="col-12">
					<label for="lname" class="form__label d-block mb-8 text-sm fw-bold">Last name</label>
					<Field
						id="lname"
						validate-on-input
						type="text"
						placeholder="Last name"
						name="lname"
						rules="required"
						class="form__input w-100 px-12 py-8 text-md"
					/>
				</div>
			</div>

			<div :class="['row mb-16', {'has-error': !!errors.email}]">
				<div class="col-12">
					<label for="email" class="form__label d-block mb-8 text-sm fw-bold">Email</label>
					<Field
						id="email"
						validate-on-input
						name="email"
						rules="required|email"
						type="email"
						placeholder="Email"
						class="form__input w-100 px-12 py-8 text-md"
					/>
				</div>
			</div>

			<div class="row mb-16">
				<div :class="['col-6', {'has-error': !!errors.postcode}]">
					<label for="postcode" class="form__label d-block mb-8 text-sm fw-bold">Postcode</label>
					<Field
						id="postcode"
						validate-on-input
						type="text"
						placeholder="####"
						name="postcode"
						rules="digits:4"
						class="form__input w-100 px-12 py-8 text-md"
					/>
				</div>
				<div :class="['col-6', {'has-error': !!errors.code || invalidCode}]">
					<label for="code" class="form__label d-block mb-8 text-sm fw-bold">Symbol code</label>
					<Field
						id="code"
						validate-on-input
						type="text"
						placeholder="####"
						name="code"
						rules="required"
						class="form__input w-100 px-12 py-8 text-md"
					/>
				</div>
			</div>

			<div :class="['row mb-16', {'has-error': tcs === false}]">
				<div class="col-12 d-flex">
					<div class="position-relative">
						<input id="tcs" v-model="tcs" type="checkbox" class="form__input"><span />
					</div>
					<label for="tcs" class="text-xs ps-8 form__label">I agree to the competition <a
						href="/tcs.pdf"
						target="_blank"
					>Terms
						and
						Conditions</a>, <a href="https://www.japan.travel/en/privacy-policy/" target="_blank">Privacy
						Policy</a> and to receive news and special offers from JNTO and
						prize partners.</label>
				</div>
			</div>

			<div :class="['row mb-16', {'has-error': !!errors.email}]">
				<div class="col-12">
					<p v-if="error" class="text-sm color-danger">
						Oops. Something went wrong. Please try again later.
					</p>
					<button
						v-if="!closed"
						type="submit"
						:disabled="!meta.valid || !tcs || sending"
						class="form__submit w-100 py-12"
					>
						<span v-if="sending">Sending...</span>
						<span v-else>Submit</span>
					</button>
					<button
						v-else
						type="button"
						disabled
						class="form__submit w-100 py-12"
					>
						Competition closed
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<p class="form__terms text-xs mb-0">
						*Vouchers provided are eligible to be used only on products inside the Japanese Brands
						Collection
						from Beauty Affairs AU. For more information read the <a href="/tcs.pdf" target="_blank">Terms
							and Conditions</a>.
					</p>
				</div>
			</div>
		</div>
	</ValidationForm>
	<div v-else class="form form--success d-flex flex-column justify-content-between p-24 pt-32 color-secondary">
		<div>
			<h2 class="h4 mb-0">
				Good luck!
			</h2>
			<p class="my-16 text-md text-center">
				You have one entry into the prize draw.<br>
				Find the other symbols for more chances to win.
			</p>
			<a :href="fbShare" target="_blank" class="form__share form__share--fb py-12 mb-16"><img
				src="@/assets/images/ico-fb.svg"
				aria-hidden="true"
				class="pe-16"
			> Share to Facebook</a>
			<button class="form__share form__share--fb py-12 mb-16" @click="reset()">
				find the other codes now
			</button>
			<!--		<a :href="twShare" target="_blank" class="form__share form__share&#45;&#45;tw py-12 mb-16"><img src="@/assets/images/ico-tw.svg" aria-hidden="true" class="pe-16"> Share to Twitter</a>-->
			<!--		<a :href="mailShare" target="_blank" class="form__share form__share&#45;&#45;mail py-12"><img src="@/assets/images/ico-mail.svg" aria-hidden="true" class="pe-16"> Share to email</a>-->
		</div>
		<p class="form__terms text-xs mb-0">
			*Vouchers provided are eligible to be used only on products inside the Japanese Brands Collection
			from Beauty Affairs AU. For more information read the <a href="/tcs.pdf" target="_blank">Terms and
				Conditions</a>.
		</p>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
	Form as ValidationForm, Field, defineRule,
} from 'vee-validate';
import {
	required, email, digits,
} from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('digits', digits);

declare global {
	interface Window {
		fbq: any;
	}
}

const fbq = window.fbq;

export default defineComponent({
	components: { ValidationForm, Field },
	data: () => ({
		tcs: true, status: false, sending: false, error: false, invalidCode: false, closed: true,
	}),
	computed: {
		fbShare() {
			return `https://www.facebook.com/share.php?u=${process.env.VUE_APP_SITE_URL}`;
		},
		twShare() {
			return `https://twitter.com/intent/tweet?text=Search+the+hidden+beauty+of+Japan+${process.env.VUE_APP_SITE_URL}`;
		},
		mailShare() {
			return `mailto:?subject=Search the hidden beauty of Japan&body=${process.env.VUE_APP_SITE_URL}`;
		},
	},
	methods: {
		reset() {
			this.status = false;
			this.sending = false;
			this.error = false;
			this.invalidCode = false;
		},
		onSubmit: function (values: { fname: string; lname: string; email: string; code: string; postcode: string }) {
			if (this.closed) {
				return;
			}

			this.reset();
			this.sending = true;

			const utmCampaign = window.location.search.match(/(utm_campaign=([^&]*))/);
			const utmSource = window.location.search.match(/(utm_source=([^&]*))/);
			const utmMedium = window.location.search.match(/(utm_medium=([^&]*))/);

			const apiUrl = 'https://repopul8.circul8.com.au/api/save/';
			const data = JSON.stringify({
				'campaign': '6026ae0d43909adaa2f97396',
				'fname': values.fname, // string, required
				'lname': values.lname, // string, required
				'email': values.email, // string, required, unique (max 1×)
				'code': values.code, // string, required
				'postcode': values.postcode, // string, required
				'tcs': this.tcs, // boolean, required
				'utm_campaign': utmCampaign === null ? '' : utmCampaign[2],
				'utm_source': utmSource === null ? '' : utmSource[2],
				'utm_medium': utmMedium === null ? '' : utmMedium[2],
			});

			const xhr = new XMLHttpRequest();

			// eslint-disable-next-line @typescript-eslint/no-this-alias,unicorn/no-this-assignment
			const self = this;
			xhr.addEventListener('readystatechange', function () {
				if (this.readyState === 4) {
					const data = JSON.parse(this.responseText);
					if (data.status === 'ok') {
						self.status = true;
						fbq('track', 'Subscribe');
					} else if (data.status === 'error') {
						for (const error of data.errors) {
							if (error === 'code is invalid.') {
								self.invalidCode = true;
								self.$gtm.trackEvent({ event: 'Incorrect' });
							}
						}
						self.status = false;
						if (!self.invalidCode) {
							self.error = true;
						}
					}
				}

				if (this.readyState > 1) {
					self.sending = false;
				}
			});

			xhr.open('POST', apiUrl);
			xhr.setRequestHeader('Content-Type', 'application/json');

			xhr.send(data);
		},
	},
});
</script>

<style scoped lang="scss">
.form {

	background: white;
	box-shadow: 0 r(40) r(20) rgba(78, 77, 77, 0.16);
	border-radius: r(8);
	border: 2px solid $primary;

	&--disabled {
		opacity: .3;
		pointer-events: none;
	}

	&--success {
		@include media-breakpoint-up(md) {
			min-height: r(686);
		}
	}

	&__input {
		border: 1px solid #BDBDBD;
		box-sizing: border-box;
		border-radius: 2px;

		&:focus {
			outline-color: $gray-3;
		}

		&[type=checkbox] {
			position: absolute;
			top: 1px;
			left: 2px;
			opacity: 0;

			+ span {
				flex: 0 0 auto;
				display: block;
				width: r(16);
				height: r(16);
				border: 1px solid #BDBDBD;
				box-sizing: border-box;
				border-radius: 2px;
			}

			&:checked + span {
				border-color: $gray-3;
				background: url(~@/assets/images/ico-check.svg) center no-repeat;
			}
		}

		.has-error & {
			border-color: $primary;
		}
	}

	&__label {
		a {
			color: $secondary;

			&:hover {
				text-decoration: none;
			}
		}

		.has-error & {
			color: $primary;
		}
	}

	&__submit {
		border-radius: 2px;
		color: white;

		background: linear-gradient(180deg, #D8151C 0%, #B71016 85.42%);
		border: 1px solid transparent;

		font-size: r(20);
		letter-spacing: 0.1em;
		font-weight: bold;
		text-transform: uppercase;

		&:disabled {
			background: $gray-3;
			border: 1px solid $gray-3;
		}
	}

	&__terms {
		a {
			color: $secondary;
		}
	}

	&__banner {
		min-height: r(690);
		object-fit: cover;
		mask-image: url(~@/assets/images/banner-bottom-mask.svg);
		mask-size: cover;
		mask-position: center;
	}

	&__share {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		font-size: r(20);
		font-weight: bold;
		text-decoration: none;
		text-transform: uppercase;

		border: none;
		color: white;
		background: linear-gradient(220.77deg, #E3383E 13.53%, #97191E 78.49%);
		border-radius: 2px;

		img {
			filter: contrast(2);
		}

		&--tw img {
			height: 16px;
		}

		&--mail img {
			height: 14px;
		}
	}
}
</style>
