import { createApp } from 'vue';
import { createGtm } from 'vue-gtm';
import VueKinesis from 'vue-kinesis';
import App from './App.vue';
import store from './store';

createApp(App)
	.use(store)
	.use(VueKinesis)
	.use(createGtm({ id: 'GTM-M5X5GMM' },
	)).mount('#app');
