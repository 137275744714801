
import { defineComponent } from 'vue';
import {
	Form as ValidationForm, Field, defineRule,
} from 'vee-validate';
import {
	required, email, digits,
} from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('digits', digits);

declare global {
	interface Window {
		fbq: any;
	}
}

const fbq = window.fbq;

export default defineComponent({
	components: { ValidationForm, Field },
	data: () => ({
		tcs: true, status: false, sending: false, error: false, invalidCode: false, closed: true,
	}),
	computed: {
		fbShare() {
			return `https://www.facebook.com/share.php?u=${process.env.VUE_APP_SITE_URL}`;
		},
		twShare() {
			return `https://twitter.com/intent/tweet?text=Search+the+hidden+beauty+of+Japan+${process.env.VUE_APP_SITE_URL}`;
		},
		mailShare() {
			return `mailto:?subject=Search the hidden beauty of Japan&body=${process.env.VUE_APP_SITE_URL}`;
		},
	},
	methods: {
		reset() {
			this.status = false;
			this.sending = false;
			this.error = false;
			this.invalidCode = false;
		},
		onSubmit: function (values: { fname: string; lname: string; email: string; code: string; postcode: string }) {
			if (this.closed) {
				return;
			}

			this.reset();
			this.sending = true;

			const utmCampaign = window.location.search.match(/(utm_campaign=([^&]*))/);
			const utmSource = window.location.search.match(/(utm_source=([^&]*))/);
			const utmMedium = window.location.search.match(/(utm_medium=([^&]*))/);

			const apiUrl = 'https://repopul8.circul8.com.au/api/save/';
			const data = JSON.stringify({
				'campaign': '6026ae0d43909adaa2f97396',
				'fname': values.fname, // string, required
				'lname': values.lname, // string, required
				'email': values.email, // string, required, unique (max 1×)
				'code': values.code, // string, required
				'postcode': values.postcode, // string, required
				'tcs': this.tcs, // boolean, required
				'utm_campaign': utmCampaign === null ? '' : utmCampaign[2],
				'utm_source': utmSource === null ? '' : utmSource[2],
				'utm_medium': utmMedium === null ? '' : utmMedium[2],
			});

			const xhr = new XMLHttpRequest();

			// eslint-disable-next-line @typescript-eslint/no-this-alias,unicorn/no-this-assignment
			const self = this;
			xhr.addEventListener('readystatechange', function () {
				if (this.readyState === 4) {
					const data = JSON.parse(this.responseText);
					if (data.status === 'ok') {
						self.status = true;
						fbq('track', 'Subscribe');
					} else if (data.status === 'error') {
						for (const error of data.errors) {
							if (error === 'code is invalid.') {
								self.invalidCode = true;
								self.$gtm.trackEvent({ event: 'Incorrect' });
							}
						}
						self.status = false;
						if (!self.invalidCode) {
							self.error = true;
						}
					}
				}

				if (this.readyState > 1) {
					self.sending = false;
				}
			});

			xhr.open('POST', apiUrl);
			xhr.setRequestHeader('Content-Type', 'application/json');

			xhr.send(data);
		},
	},
});
