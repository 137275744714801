<template>
	<header class="header container-xl">
		<nav class="row header__nav align-items-center">
			<div class="header__logo py-12 col-6 col-md-2 col-xl-3 d-flex justify-content-start justify-content-md-end justify-content-xl-start">
				<a href="https://www.japan.travel/en/au" target="_blank"><img src="@/assets/images/logo.svg" alt="JNTO logo"></a>
			</div>
			<ul :class="['header__menu d-flex flex-column flex-md-row justify-content-md-between align-items-center mb-0 py-20 col-8 col-lg-7 offset-lg-1 offset-xl-0 col-xl-6 list-unstyled list-inline', {'header__menu--active': burger}]">
				<li class="list-inline-item mr-0 mb-16 mb-md-0 mt-5 mt-md-0">
					<a href="https://www.japan.travel/en/destinations" target="_blank">Destinations</a>
				</li>
				<li class="list-inline-item mr-0 mb-16 mb-md-0">
					<a href="https://www.japan.travel/en/plan/" target="_blank">Plan Your Trip</a>
				</li>
				<li class="list-inline-item mr-0 mb-16 mb-md-0">
					<a href="https://www.japan.travel/en/au/experience/" target="_blank">Experience</a>
				</li>
				<li class="list-inline-item mr-0 mb-16 mb-md-0">
					<a href="https://www.japan.travel/en/au/all-news-and-blogs/" target="_blank">News & Blog</a>
				</li>
			</ul>
			<button class="header__hamburger col-6 d-flex d-md-none justify-content-end" aria-hidden="true" @click="burger = !burger">
				<img v-if="!burger" src="@/assets/images/ico-burger.svg">
				<img v-else src="@/assets/images/ico-burger-closed.svg">
			</button>
		</nav>
	</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ data: () => ({ burger: false }) });
</script>

<style scoped lang="scss">
.header {
	&__logo {
		position: relative;
		z-index: 3;
	}

	&__nav {
		background: white;
	}

	&__menu {
		font-size: r(18);
		a {
			font-family: $font-family-base;
			font-weight: bold;
			text-decoration: none;
			color: black;

			&:hover {
				text-decoration: underline;
			}
		}

		@include media-breakpoint-down(md) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			max-height: 100vh;
			transform: translateX(100%);
			transition: all .4s ease-in;
			background-color: white;
			z-index: 1;
			&--active {
				transform: translateX(0);
			}
		}
	}

	&__hamburger {
		position: relative;
		z-index: 3;

		border: none;
		outline: none;
		background-color: transparent;
	}
}
</style>
