<template>
	<footer class="bg-gray-1 footer">
		<div class="container-xl">
			<div class="row justify-content-center justify-content-lg-between align-items-center py-32">
				<div class="col-6 col-lg-2 order-1 order-lg-0">
					<img src="@/assets/images/logo-footer.svg" alt="JNTO logo">
				</div>
				<div class="col-12 col-sm-8 col-lg-6 order-0 order-lg-1 mb-56 mb-lg-0">
					<ul class="footer__nav list-unstyled d-flex flex-column flex-sm-row justify-content-around align-items-center mb-0">
						<li class="list-inline-item">
							<a href="https://www.japan.travel/en/privacy-policy/" target="_blank" class="text-sm color-gray-2">Privacy Policy</a>
						</li>
						<li class="list-inline-item">
							<a href="https://www.japan.travel/en/cookie-policy/" target="_blank" class="text-sm color-gray-2">Cookie Policy</a>
						</li>
						<li class="list-inline-item">
							<a href="/tcs.pdf" target="_blank" class="text-sm color-gray-2">Terms of Use</a>
						</li>
						<li class="list-inline-item">
							<a href="https://www.japan.travel/en/links/" target="_blank" class="text-sm color-gray-2">Links</a>
						</li>
					</ul>
				</div>
				<div class="footer__socials col-6 col-lg-2 order-1 order-lg-2 d-flex justify-content-end">
					<a href="https://www.facebook.com/visitjapanau" target="_blank" class="d-inline-block ms-12"><img src="@/assets/images/ico-fb.svg" alt="Facebook page"></a>
					<a href="https://www.instagram.com/visitjapanau/" target="_blank" class="d-inline-block ms-12"><img src="@/assets/images/ico-ig.svg" alt="Instagram page"></a>
					<a href="https://www.youtube.com/channel/UCuLob4bDZojxxbkAiY4-lGg" target="_blank" class="d-inline-block ms-12"><img src="@/assets/images/ico-yt.svg" alt="YouTube channel"></a>
				</div>
			</div>
		</div>
	</footer>
</template>

<style scoped lang="scss">
.footer {
	&__nav {
		a {
			text-decoration: none;
			&:hover {
				color: $gray-3;
			}
		}
	}

	&__socials {
		a:hover {
			opacity: 0.6;
		}
	}
}
</style>
